

@layer mantine;
@layer reset, base, tokens, recipes, utilities;

@import '../../node_modules/@mantine/core/styles.css' layer(mantine);



body {
    background-color: light-dark(var(--colors-light), var(--colors-dark));
    color: light-dark(var(--colors-dark), var(--colors-light));
}
